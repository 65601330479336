<template>
  <div class="recruitBox">
    <!-- <img src="@/assets/img/home/cooper_top_bg.png" alt="" style="width: 100%;">
    <img src="@/assets/img/home/cooper_bottom_bg.png" alt="" style="width: 100%;"> -->

    <!-- 右侧英文图 -->
    <img
      src="@/assets/img/home/cooper_bg_out.png"
      alt=""
      class="right_en_img"
    />

    <!-- 联系电话图 -->
    <img src="@/assets/img/home/cooper_bg_in.png" alt="" class="phone_img" />

    <!-- 顶部背景图片 -->
    <img src="@/assets/img/home/cooper_top_bg.png" alt="" class="top_bg" />

    <!-- 顶部背景字 -->
    <img src="@/assets/img/home/cooper_top.png" class="top_bg_text" />
    <!-- <div class="top_bg">
      <img src="@/assets/img/home/cooper_top.png" alt="" style="width: 100%;">
    </div> -->

    <!-- 底部 -->
    <div class="bottom_bg">
      <!-- 左上角 tag -->
      <div class="top_tag"></div>
      <div class="formBox">
        <div class="form_item" style="padding: .15rem .3rem;">
          <div class="label">请上传真实照片</div>
          <div class="content">
            <img
              v-if="form.avatar == ''"
              src="@/assets/img/df_avator.png"
              alt=""
              class="user_img"
            />
            <img v-else :src="form.avatar" alt="" class="user_img" />
            <input
              type="file"
              accept="image/*"
              @change="afterRead"
              id="inputId"
            />

            <img
              src="@/assets/img/icon_arrow_grey.svg"
              alt=""
              class="arrow_right_img"
            />
          </div>
        </div>
        <div class="tips">有利于提高面试机会</div>
        <div class="form_item">
          <div class="label">手机号码</div>
          <div class="content">
            <input
              type="number"
              placeholder="请填写"
              v-model="form.phoneNumber"
            />
          </div>
        </div>
        <div class="form_item">
          <div class="label">姓名</div>
          <div class="content">
            <input type="text" placeholder="请填写" v-model="form.nickName" />
          </div>
        </div>
        <div class="form_item">
          <div class="label">性别</div>
          <div class="content" @click="showSex = true">
            <span :style="{ color: sex == '' ? '#c0c0c0' : 'black' }">{{
              sex == "" ? "请选择" : sex
            }}</span>
            <img
              src="@/assets/img/icon_arrow_grey.svg"
              alt=""
              class="arrow_right_img"
            />
          </div>
        </div>
        <div class="form_item">
          <div class="label">年龄</div>
          <div class="content">
            <input type="number" placeholder="请填写" v-model="form.age" />
          </div>
        </div>
        <div class="form_item">
          <div class="label">身高</div>
          <div class="content">
            <input type="number" placeholder="请填写" v-model="form.height" />
            <span :style="{ color: form.height == '' ? '#c0c0c0' : '#333' }"
              >cm</span
            >
          </div>
        </div>
        <div class="form_item">
          <div class="label">工作城市</div>
          <div class="content" @click="showCityList">
            <span :style="{ color: work_city == '' ? '#c0c0c0' : 'black' }">{{
              work_city == "" ? "请选择" : work_city
            }}</span>
            <img
              src="@/assets/img/icon_arrow_grey.svg"
              alt=""
              class="arrow_right_img"
            />
          </div>
        </div>
        <div class="form_item">
          <div class="label">常驻地址</div>
          <div class="content">
            <input
              type="text"
              placeholder="请填写真实地址, 如：侯家大院100号"
              v-model="form.residentAddress"
            />
          </div>
        </div>
        <div class="form_area">
          <div class="label">工作简介</div>
          <div class="content">
            <textarea
              name=""
              id=""
              placeholder="描述一下你工作特点或者擅长"
              v-model="form.introduction"
              class="work_desc"
              maxlength="200"
            ></textarea>
          </div>
          <p class="text-length">{{ form.introduction.length }} / 200</p>
        </div>

        <van-button
          round
          type="info"
          block
          color="#E82B2F"
          class="submit_btn"
          @click="submit"
          >提交审核</van-button
        >
      </div>
    </div>

    <!-- 性别选项 -->
    <van-action-sheet
      v-model="showSex"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelectSex"
    />
  </div>
</template>

<script>
import { uploadFiles, recruit } from "@/api/index";
import { isValidPhoneNumber, hasEmptyValues } from "@/utils/index";

export default {
  data() {
    return {
      form: {
        avatar: "",
        phoneNumber: "",
        nickName: "",
        sex: "",
        age: "",
        height: "",
        serveCityId: "",
        residentAddress: "",
        introduction: ""
      },
      sex: "", // 纯展示，不提交
      work_city: "", // 纯展示，不提交
      showSex: false,
      actions: [{ name: "男" }, { name: "女" }]
    };
  },
  created() {
    console.log("初始化");
  },
  activated() {
    /**
     * 缓存的组件点击时调用
     */
    const formData = JSON.parse(sessionStorage.getItem("formData"));
    if(formData){
      sessionStorage.removeItem("formData");
      this.form = formData;
      this.sex = formData.sex == 1 ? '男' : '女';
    }
    const selectCity = JSON.parse(sessionStorage.getItem("selectCity"));
    if (selectCity) {
      sessionStorage.removeItem("selectCity");
      this.form.serveCityId = selectCity.cityInfo.id;
      this.work_city = selectCity.cityInfo.name;
    }
  },
  deactivated(){
    this.form = {
      avatar: "",
      phoneNumber: "",
      nickName: "",
      sex: "",
      age: "",
      height: "",
      serveCityId: "",
      residentAddress: "",
      introduction: ""
    };
    this.sex = "";
    this.work_city = "";
    this.showSex = false;
  },
  methods: {
    onSelectSex(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showSex = false;
      this.sex = item.name;
      this.form.sex = item.name == "男" ? 1 : 0;
    },
    showCityList() {
      sessionStorage.setItem('formData', JSON.stringify(this.form))
      this.$router.push("/cityList");
    },
    // 选择图片回调
    afterRead(e) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      document.getElementById("inputId").value = "";
      uploadFiles(data).then(res => {
        if (res.data) {
          this.form.avatar = res.data;
        }
      });
    },
    submit() {
      if (!isValidPhoneNumber(this.form.phoneNumber)) {
        this.$toast("请填写正确的手机号");
        return;
      }
      // if (hasEmptyValues(this.form)) {
      //   this.$toast("请检查您的填写~");
      //   return;
      // }
      console.log(this.form);
      recruit(this.form).then(res => {
        if (res.code == 10000) {
          this.$toast("提交成功");
          this.$router.back();
        }
      });
    }
  },
  mounted() {},
  components: {},
  computed: {}
};
</script>

<style lang="less" scoped>
@import "../../styles/base.less";
#inputId {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
}

.recruitBox {
  .paddingBox();
  background-color: #4136ed;
  position: relative;
  padding-top: 1rem;
}

.right_en_img {
  position: absolute;
  right: 0;
  top: 0.4rem;
  width: 1rem;
}

.phone_img {
  position: absolute;
  right: 1.5rem;
  top: 4.6rem;
  width: 3.5rem;
}

.top_bg {
  width: 100%;
  position: relative;
  z-index: 1;
  // height: 4.933rem;
  // height: 185px;
  // background-image: url(../../assets/img/home/cooper_top_bg.png);
  // background-repeat: no-repeat;
  // background-size:100% 100%;
  margin-bottom: 0.3rem;
  // padding: .55rem .3rem;
}

.top_bg_text {
  width: 100%;
  position: absolute;
  top: 1.3rem;
  left: 0.1rem;
  transform: scale(0.85);
  z-index: 2;
}

.bottom_bg {
  // width: 100%;
  // height: 32rem;
  background-image: url(../../assets/img/home/cooper_bottom_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0.55rem 0.4rem;
  .top_tag {
    background-color: #4136ed;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }

  .formBox {
    padding: 1rem 0;
    .form_item {
      border: 1px solid #efefef;
      border-radius: 0.1rem;
      padding: 0.3rem;
      margin-bottom: 0.4rem;
      .flex-row-between();
      .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 0.2rem;
        position: relative;
        .user_img {
          width: 0.8rem;
          height: 0.8rem;
        }
        .arrow_right_img {
          width: 0.3rem;
          height: 0.3rem;
          margin-left: 0.2rem;
        }
        input {
          .input-style;
          width: 100%;
          text-align: right;
        }
        input::placeholder {
          color: #c0c0c0;
          /* 设置placeholder的颜色 */
          opacity: 1;
          /* Firefox */
        }
      }
    }
    .tips {
      color: #f8a342;
      margin-bottom: 0.4rem;
    }
    .form_area {
      border: 1px solid #efefef;
      border-radius: 0.1rem;
      padding: 0.3rem;
      .content {
        height: 3rem;
        margin: 0.45rem 0;
        .work_desc {
          .input-style;
          width: 100%;
          height: 100%;
          line-height: 0.48rem;
          padding-top: 0; /* 上内边距可能需要调整 */
          resize: none; /* 防止用户调整大小 */
        }
        textarea::placeholder {
          color: #c0c0c0;
          /* 设置placeholder的颜色 */
          opacity: 1;
          /* Firefox */
        }
      }
      .text-length {
        text-align: right;
      }
    }
  }

  .submit_btn {
    margin-top: 0.8rem;
  }
}
</style>
